"use client";

import React, { useState, useEffect, useRef, Ref, useCallback } from 'react';
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './css/HomeSlider.scss';
import { getGrid, videoFunctions } from './functions';
import SliderMedia from './components/SliderMedia';
import SliderButtons from './components/SliderButtons';
import { HomeSlide, HomeSlideButton } from './types';
import { gtmSender, purify, stripTags } from '@/helpers';

export default function HomeSlider({ slides, className, delay, loop, bgColor, domPersistent }: {
    readonly slides: HomeSlide[],
    readonly className?: string,
    readonly delay?: number,
    readonly loop?: boolean,
    readonly bgColor?: string,
    readonly domPersistent?: boolean,
}) {
  const [ isLoaded, setIsLoaded ] = useState(false);

  const defBg = slides[0].bg_image_url ?? '';
  const [ currentBg, setCurrentBg ] = useState(defBg);

  const swprRef: Ref<SwiperRef> = useRef(null);
  const currentBgRef: Ref<HTMLElement> = useRef(null);
  const prevBgRef: Ref<HTMLElement> = useRef(null);

  const [ videos, setVideos ] = useState<{ id: string, video: HTMLVideoElement }[]>([]);

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="' + className + '" data-index="' + index + '"></span>';
    },
  };

  const fn = videoFunctions({ videos, setVideos });

  const slideChanged = (swiper: any) => {
    fn.stopVideos();
    
    try {
      const slide = swiper?.slides?.[swiper.activeIndex]?.getAttribute('data-bg');
      
      currentBgRef.current?.classList.remove('animated');
      setTimeout(() => {
        if(currentBgRef.current) {
          currentBgRef.current.style.backgroundImage = `url(${slide})`;
          currentBgRef.current.classList.add('animated');
        }
      }, 1);
      
      setTimeout(() => {
        if(prevBgRef.current) {
          prevBgRef.current.style.backgroundImage = `url(${slide})`;
        }
        if(!domPersistent) {
          if(currentBg !== slide) setCurrentBg(slide);
        }
      }, 150);
    } catch(e) {}
  };

  const slidesPushed = useRef<boolean>();
  
  const slideItems: Record<string,string>[] = slides.map((slide: HomeSlide, index: number) => {
    return {
      creative_name: slide?.creative_name ?? stripTags(slide?.title ?? 'unnamed'),
      creative_slot: slide?.creative_slot ?? 'slider',
      promotion_id: slide?.promotion_id ?? String(slide.id),
      promotion_name: slide?.promotion_name ?? stripTags(slide?.title ?? 'unnamed'),
      slide_index: String(index),
    };
  });

  const pushSlides = useCallback(() => {
    try {
      if(!slidesPushed.current) {
        slidesPushed.current = true;
        if(slideItems?.length) {
          gtmSender({ ecommerce: null });
          gtmSender({
            "event": "view_promotion",
            "ecommerce": {
              "items": slideItems,
            }
          });
        }
      } else {
        if(!slidesPushed.current) setTimeout(pushSlides, 250);
      }
    } catch(e) {}
  }, [ slideItems ]);

  useEffect(() => {
    setIsLoaded(true);
    pushSlides();
  }, [ pushSlides ]);

  if(!slides?.length) return (<></>);

  return (
    <>
      <Swiper
        ref={swprRef}
        pagination={pagination}
        loop={loop ?? false}
        navigation={true}
        modules={[
          Pagination,
          Navigation,
          Autoplay
        ]}
        onSlideChange={slideChanged}
        autoplay={{
          delay: delay ?? 5000,
          disableOnInteraction: true,
        }}
        id="home-slider"
        className={`home-slider ${className ?? ''} ${(loop ?? false) ? 'loop' : ''} ${isLoaded ? 'opacity-100 transition-all duration-300' : 'opacity-0'}`}
        style={{
          animationDuration: (delay ?? 5000) + 'ms',
          backgroundColor: bgColor ?? 'rgba(0,0,0,.1)'
        }}
      >
        {
          slides.map((slide: HomeSlide, index: number) => {
            const [
              gridFirst,
              gridSecond,
              gridPlacement
            ] = getGrid(slide?.grid, slide?.placement);

            slide.button = slide.button?.map((item: HomeSlideButton) => {
              return {
                ...item,
                creativeName: slide?.creative_name ?? stripTags(slide?.title ?? 'unnamed'),
                creativeSlot: slide?.creative_slot ?? 'slider',
                promotionId: slide?.promotion_id ?? String(slide.id),
                promotionName: slide?.promotion_name ?? stripTags(slide?.title ?? 'unnamed'),
                slideIndex: String(index),
              };
            });

            return (
              <SwiperSlide
                key={slide.id}
                data-bg={slide?.bg_image_url}
                data-creative-name={slide?.creative_name ?? stripTags(slide?.title ?? 'unnamed')}
                data-creative-slot={slide?.creative_slot ?? 'slider'}
                data-promotion-id={slide?.promotion_id ?? String(slide.id)}
                data-promotion-name={slide?.promotion_name ?? stripTags(slide?.title ?? 'unnamed')}
                data-slide-index={index}
              >
                <div className={`container flex flex-col items-stretch justify-evenly ${gridPlacement} lg:items-center lg:justify-between lg:gap-8`}>
                  
                  <div className={`text-content flex-shrink w-full ${gridFirst}`}>
                    <h2 className="text-[24px] lg:text-[32px] xl:text-[42px] font-bold leading-tight" dangerouslySetInnerHTML={purify(slide?.title)}></h2>
                    <p className="text-sm sm:text-[18px] leading-normal mt-8 mb-3" dangerouslySetInnerHTML={purify(slide?.sub_title)}></p>

                    <div className="buttons">
                      <SliderButtons buttons={slide?.button} />
                    </div>
                  </div>

                  <div className={`media-content w-full ${gridSecond}`}>
                    <SliderMedia slide={slide} onAdd={fn.addVideo} onRemove={fn.removeVideo} onInteraction={() => { swprRef.current?.swiper.autoplay.stop(); }} />
                  </div>

                </div>
              </SwiperSlide>
            );
          })
        }

        {/* These are needed to animate background image on slide change */}
        <span
          ref={prevBgRef}
          slot="container-start"
          style={{ backgroundImage: `url(${defBg})` }}
        ></span>
        <span
          ref={currentBgRef}
          slot="container-end"
          className={'animated'}
          style={{ backgroundImage: `url(${defBg})` }}
        ></span>
      </Swiper>
    </>
  );
}