"use client";

import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/css';
import 'swiper/css/navigation';
import { useEffect, useRef, useState } from "react";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import { linkUrl } from "@/helpers";
import { videoFunctions } from "../HomeSlider/functions";
import styles from "./css/SingleVideos.module.scss";

export default function SingleVideos({ videos, domPersistent }: {
  readonly videos: {
    id: number,
    caption: string,
    video_poster_url: string,
    video_url: string,
  }[],
  readonly domPersistent?: boolean,
}) {

  const videoContainer = useRef<HTMLDivElement>(null);
  const selectorContainer = useRef<HTMLDivElement>(null);
  const swiperRef = useRef<any>();

  const [ isLoaded, setIsLoaded ] = useState(false);

  const [ selectedCategory, setSelectedCategory ] = useState(0);
  const [ videoRefs, setVideoRefs ] = useState<{ id: string, video: HTMLVideoElement }[]>([]);

  const fn = videoFunctions({ videos: videoRefs, setVideos: setVideoRefs });
  
  const selectCategory = (index: number) => {
    if(swiperRef.current?.swiper) {
      let fix = Math.floor((swiperRef.current.swiper.visibleSlides.length - 1) / 2);
      let moveTo = Math.max(0, Math.min(swiperRef.current.swiper.slides.length - 1, index - fix));
      swiperRef.current.swiper.slideTo(moveTo);
    }

    let sel: number = index;
    if(!domPersistent) {
      setSelectedCategory(index);
      sel = index === undefined ? selectedCategory : index;
    }

    if(selectorContainer.current) {
      selectorContainer.current.querySelectorAll('.video-selector').forEach((item: any, i: number) => {
        item.classList.remove(styles.active);
        if(i === sel) item.classList.add(styles.active);
      });
    }

    if(videoContainer.current) {
      videoContainer.current.querySelectorAll('.video-canvas').forEach((item: any, i: number) => {
        item.classList.remove(styles.active);
        if(i === sel) item.classList.add(styles.active);
      });
    }
  };

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <div ref={selectorContainer} className={`${isLoaded ? 'opacity-100 transition-all duration-300' : 'opacity-0'} container mt-10 lg:mt-[86px]`}>
      <Swiper
        ref={swiperRef}
        loop={false}
        breakpoints={{
          0: {
            slidesPerView: 'auto',
          },
          640: {
            slidesPerView: Math.min((videos?.length ?? 0), 2),
          },
          1024: {
            slidesPerView: Math.min((videos?.length ?? 0), 4),
          },
          1280: {
            slidesPerView: Math.min((videos?.length ?? 0), 5),
          },
        }}
        watchSlidesProgress={true}
        className="!me-auto sm:!-me-2 mb-9"
      >
        {
          videos.map((item: any, index: number) => {
            return (
              <SwiperSlide
                key={item.id}
                className="flex justify-stretch w-full !flex-shrink-[100] sm:w-auto sm:!flex-shrink-0"
                onClick={ () => selectCategory(index) }
              >
                <div className={`video-selector ${styles['video-selector-button']} ${index === 0 ? styles.active : ''} flex justify-center items-center text-center my-1 mx-2 [&:first-child]:ms-0 whitespace-nowrap px-7 py-3 rounded-md text-[13px] font-semibold cursor-pointer select-none bg-slate-200 hover:bg-opacity-80 transition-all`}>
                  { item.caption }
                </div>
              </SwiperSlide>
            );
          })
        }
      </Swiper>

      <div ref={videoContainer} className={styles.videos}>
        {
          videos.map((item: any, index: number) => {
            return (
              <div key={item.id} className={`video-canvas ${index === 0 ? styles.active : ''} ${styles.video}`}>
                {
                  (() => {
                    return (
                      <VideoPlayer
                        src={ linkUrl(process.env.NEXT_PUBLIC_VIDEO_ROOT + '/ornek-videolar/', item?.video_url ?? '') ?? '' }
                        poster={ linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/images/video-cover/', item?.video_poster_url ?? '') ?? '' }
                        onAdd={fn.addVideo} onRemove={fn.removeVideo}
                      />
                    );
                  })()
                }
              </div>
            );
          })
        }
      </div>
    </div>
  );
}
