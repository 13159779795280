"use client";

import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/css';
import 'swiper/css/navigation';
import "./css/SampleVideos.scss";
import DPIcon from "../DPIcon/DPIcon";
import _e from "@/helpers/texts";
import { PageData } from "@/types/page";
import { Navigation } from "swiper/modules";
import { linkUrl } from "@/helpers";
import { useEffect, useState } from "react";
import ImageWithFallback from "../ImageWithFallback/ImageWithFallback";
import LoadingLink from "../LoadingLink/LoadingLink";

export default function SampleVideos({ rootSlug, videos }: {
  readonly rootSlug: string,
  readonly videos: PageData[],
}) {
  
  const [ isLoaded, setIsLoaded ] = useState(false);

  const [ videoList, setVideoList ] = useState<Record<string,any>[]>();
  const [ opacities, setOpacities ] = useState({ list: ['opacity-100'], fallback: 'opacity-100' });

  const swiperMoved = (swp: any) => {
    setVideoList((current?: Record<string,any>) => {
      return current?.map((item: Record<string,any>, index: number) => {
        index += index < swp.realIndex ? current.length : 0;

        item.opacity = opacities.list[index - swp.realIndex] ?? opacities.fallback;
        
        return item;
      });
    });
  };

  const handleResize = () => {
    setOpacities(() => {
      let opacityList = [ 'opacity-100' ];
      let opacityFallback = 'opacity-100';

      if(window?.innerWidth >= 1280) {
        opacityList = [
          'opacity-40',
          'opacity-90',
          'opacity-100',
          'opacity-90',
          'opacity-40'
        ];
        opacityFallback = 'opacity-40';
      } else if(window?.innerWidth >= 1024) {
        opacityList = [
          'opacity-50',
          'opacity-100',
          'opacity-50',
        ];
        opacityFallback = 'opacity-50';
      }

      return {
        list: opacityList,
        fallback: opacityFallback,
      };
    });
  };

  useEffect(() => {
    const handleFirstOrder = () => {
      const list = [ ...videos ];
      if(list?.length) {
        if(window?.innerWidth >= 1024) {
          let vid = list?.pop();
          if(vid) list?.unshift(vid);
        }
        if(window?.innerWidth >= 1280) {
          let vid = list?.pop();
          if(vid) list?.unshift(vid);
        }
      }

      setVideoList(list);
    };

    handleFirstOrder();
    handleResize();

    setIsLoaded(true);

    window?.addEventListener('resize', handleResize);
    return () => { window?.removeEventListener('resize', handleResize); };
  }, [ videos ]);

  return (
    
    <>
      <div className={`${isLoaded ? 'opacity-100 transition-all duration-300' : 'opacity-0'} container relative mt-5 lg:mt-11 mb-4 lg:mb-5`}>
        <div className="title flex justify-between items-center">
          <DPIcon icon="youtube" className="absolute -left-[20px] sm:-left-[60px] -top-[20px] -rotate-[20deg] opacity-5 text-[12rem] z-0" />
          <h2 className="text-lava text-[26px] sm:text-[2.5rem] font-bold">{ _e('Örnek Videolar') }</h2>
          <LoadingLink href={`/${rootSlug}`} title={`Doping Hafıza ${ _e('Örnek Videolar') }`} className="flex items-center text-honolulu-blue text-sm font-semibold">
            { _e('Tümünü Gör') }
            <DPIcon icon="arrow-right" />
          </LoadingLink>
        </div>
      </div>

      <div className="mx-3 sm:mx-0 overflow-hidden">
        <div className={`${isLoaded ? 'opacity-1 transition-all duration-300' : 'opacity-0'} __sample-videos-swiper overflow-hidden relative -mx-3 sm:mx-0`}>
          <div className="swiper-content relative block mx-0 lg:-mx-[230px]">
            <Swiper
              loop={isLoaded}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                640: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
                1280: {
                  slidesPerView: 5,
                },
              }}
              navigation={{
                nextEl: '.swiper-button-sample-videos-next-custom',
                prevEl: '.swiper-button-sample-videos-prev-custom',
              }}
              modules={[Navigation]}
              className="flex items-stretch !pe-12 sm:!pe-0"
              onActiveIndexChange={swiperMoved}
            >
              {
                isLoaded && videoList?.map((item: any) => {
                  return (
                    <SwiperSlide
                      key={item.id}
                      className={`flex justify-center items-stretch p-3 select-none flex-grow ${item?.opacity ?? ''} !transition-all duration-300`}
                    >
                      <LoadingLink href={ '/' + rootSlug + '/' + item.slug } className="p-3 relative shadow-md rounded-lg flex flex-col justify-between flex-grow bg-white">
                        <div className="relative p-3 overflow-hidden before:relative before:block before:pb-[56.25%]">
                          <ImageWithFallback
                            src={ linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/files/anasayfa-video-kapak/', (item.card_image_url ?? 'ayt-ornek-videolar.webp')) ?? '' }
                            fallback={ linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/files/anasayfa-video-kapak/', 'ayt-ornek-videolar.webp') ?? '' }
                            alt={item.caption}
                            fill={true}
                            sizes="100%"
                            style={{ objectFit: 'cover', objectPosition: 'center' }}
                          />
                        </div>

                        <div>
                          <div className="mt-3 text-lava text-sm font-semibold pb-3 mb-3 border-b-[1px] border-b-slate-300">
                            { item.caption }
                          </div>
                          <div className="text-sm">{ item.videoCount } { _e('Video') }</div>
                        </div>
                      </LoadingLink>
                    </SwiperSlide>
                  );
                })
              }
            </Swiper>

            <div className="custom-buttons hidden md:flex justify-center gap-8 mt-3">
              <div className="swiper-button-sample-videos-prev-custom">
                <DPIcon className="text-[40px] text-slate-400 transition-all hover:text-honolulu-blue cursor-pointer" icon="arrow-circle-left" />
              </div>
              <div className="swiper-button-sample-videos-next-custom">
                <DPIcon className="text-[40px] text-slate-400 transition-all hover:text-honolulu-blue cursor-pointer" icon="arrow-circle-right" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
