"use client";

import DPIcon from "../DPIcon/DPIcon";
import { purify } from "@/helpers";
import { useEffect, useState } from "react";
import styles from "./css/WhyDoping.module.scss";
import _e from "@/helpers/texts";
import { findAndStopAllVideos } from "../HomeSlider/functions";
import VideoDialog from "../VideoPlayer/VideoDialog";

export default function WhyDoping({ data, caption, subCaption }: {
  readonly data: Record<string,any>[],
  readonly caption?: string,
  readonly subCaption?: string,
}) {
  const [ isLoaded, setIsLoaded ] = useState(false);

  const [ videos, setVideos ] = useState<Record<string,any>[]>([ ...data ]);
  const [ videoListTrimmed, setVideoListTrimmed ] = useState(true);
  
  const selectVideo = (id: number) => {
    findAndStopAllVideos();
    setVideos((current) => {
      return current.map((item) => {
        item.dialogActive = item.id === id;
        return item;
      });
    });
  };
  
  const deselectVideo = () => {
    setVideos((current) => {
      return current.map((item) => {
        item.dialogActive = false;
        return item;
      });
    });
  };

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  if(!data.length) return (<></>);

  return (
    <div className={`${styles['why-doping']} ${isLoaded ? 'opacity-100 transition-all duration-300' : 'opacity-0'} relative mt-8 pt-[30px] pb-[25px]`}>
      <div className={`container ${styles['icon-container']} !px-4`}>
        <div className={`${styles['title']}`}>
          <h3 className="text-sm sm:text-3xl font-semibold leading-normal">{ caption ?? '' }</h3>
          <h2 className="text-[26px] sm:text-[2.5rem] font-bold mb-2 sm:mb-5 sm:mt-2 leading-normal">{ subCaption ?? '' }</h2>
        </div>

        <div className={`${styles['banner-list']} flex justify-start items-center flex-wrap -mx-4`}>
          
          {
            videos.map((item: Record<string,any>, index: number) => {
              if(item?.video_url?.substring(item?.video_url?.length - 5) !== '.m3u8') item.video_url = '';
              return (
                <div key={item.id} className={`${styles['item']} ${index > 5 && videoListTrimmed ? styles.hide : ''} w-full md:w-1/2 lg:w-1/3 xl:w-1/4 flex-grow py-2 px-4 md:py-5`}>
                  <div className={`${styles['item-content']} flex items-strect ${item?.video_url ? 'cursor-pointer' : styles['unclickable']}`} onClick={() => {
                    if(item?.video_url) selectVideo(item.id);
                  }}>
                    <div className={`${styles['item-icon']} flex justify-center items-center w-11 h-11 text-2xl md:text-4xl me-4 md:w-16 md:h-16 md:me-4 rounded-lg flex-shrink-0`}>
                      <DPIcon icon={item?.icon_url ?? 'br-badge'} className="text-white" />
                    </div>
                    <div
                      className={`${styles['item-text']} flex-grow flex flex-col justify-center items-start font-medium text-sm md:text-base`}
                      dangerouslySetInnerHTML={purify(item.caption)}
                    ></div>
                  </div>
                  {
                    item?.video_url && (
                      <VideoDialog src={item?.video_url ?? ''} poster={item?.video_poster_url ?? ''} deselectVideo={deselectVideo} dialogActive={item?.dialogActive} />
                    )
                  }
                </div>
              );
            })
          }
        </div>

        <span className={`${videoListTrimmed ? '' : 'hidden'} ${styles['show-more']} flex items-center lg:hidden mt-10 font-semibold text-sm cursor-pointer`} onClick={() => setVideoListTrimmed(false)}>
          { _e('Daha fazla göster') }
          <DPIcon icon="arrow-circle-down" className="ms-2" />
        </span>
      </div>

    </div>
  );
}
