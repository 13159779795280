"use client";

import React from 'react';
import { HomeSlideButton } from '../types';
import LoadingLink from '@/components/LoadingLink/LoadingLink';
import { gtmSender } from '@/helpers';

export default function SliderButtons ({ buttons }: {
  readonly buttons: HomeSlideButton[],
}) {
  const handleClick = (item: HomeSlideButton) => {
    try {
      gtmSender({ ecommerce: null });
      gtmSender({
        "event": "select_promotion",
        "ecommerce": {
          "items": [
            {
              "creative_name": item?.creativeName ?? '',
              "creative_slot": item?.creativeSlot ?? '',
              "promotion_id": item?.promotionId ?? '',
              "promotion_name": item?.promotionName ?? '',
              "promotion_url": item?.url ?? '',
              "slide_index": item?.slideIndex ?? '',
            }
          ],
        }
      });
    } catch(e) {}
  };

  return (
    <div className="buttons">
      {
        buttons.map((item: HomeSlideButton) => {
          return (
            <LoadingLink
              href={item.url}
              rel={item.target === 'self' ? '' : 'noopener noreferrer'}
              target={'_' + item.target} key={item.id}
              title={item?.title ?? ''}
              className="slider-btn inline-block px-4 sm:px-8 py-2 sm:py-4 mt-3 sm:mt-4 me-2 text-sm sm:text-base leading-normal font-medium rounded-lg bg-slate-50 hover:bg-white transition-all"
              onClick={() => handleClick(item) }
            >
              { item.caption }
            </LoadingLink>
          );
        })
      }
    </div>
  );
}