"use client";

import React from 'react';

import VideoPlayer from '@/components/VideoPlayer/VideoPlayer';

export default function SliderMedia ({ slide, onAdd, onRemove, onInteraction }: {
  readonly slide: Record<string,any>,
  readonly onAdd?: Function,
  readonly onRemove?: Function,
  readonly onInteraction?: Function,
}) {
  if(slide?.video_url) return (
    <VideoPlayer 
      src={slide.video_url} 
      poster={slide?.video_poster_url}
      onAdd={onAdd}
      onRemove={onRemove}
      onInteraction={onInteraction}
    />
  );

  return (
    <div className="slide-image" style={{ backgroundImage: 'url(' + slide.image_url + ')' }}></div>
  );
}