"use client";

import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/css';
import 'swiper/css/navigation';
import DPIcon from "../DPIcon/DPIcon";
import _e from "@/helpers/texts";
import { Navigation } from "swiper/modules";
import { linkUrl } from "@/helpers";
import { useEffect, useState } from "react";
import Image from "next/image";
import FsLightbox from "fslightbox-react";
import LoadingLink from "../LoadingLink/LoadingLink";

export default function Testimonials({ rootSlug, list, heading }: {
  readonly rootSlug: string,
  readonly list: Record<string, any>[],
  readonly heading: {
    readonly caption?: string,
    readonly subcap: string,
    readonly linkCap?: string,
  },
}) {

  const handleResize = () => {
    setOpacities(() => {
      let opacityList = [ 'opacity-100 z-10' ];
      let opacityFallback = 'opacity-100';
      
      if(window?.innerWidth >= 1280) {
        opacityList = [
          'opacity-30',
          'opacity-50',
          'opacity-100 !scale-110 z-10',
          'opacity-50',
          'opacity-30'
        ];
        opacityFallback = 'opacity-40';
      } else if(window?.innerWidth >= 1024) {
        opacityList = [
          'opacity-40',
          'opacity-100 !scale-110 z-10',
          'opacity-40',
        ];
        opacityFallback = 'opacity-50';
      }

      return {
        list: opacityList,
        fallback: opacityFallback,
      };
    });
  };

  const openLightbox = (id: number) => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: id
    });
  };
  
  const [ lightboxController, setLightboxController ] = useState({
    toggler: false,
    slide: 1
  });

  const lightBoxSource: string[] = [];
  list.forEach((item: Record<string,any>) => {
    lightBoxSource.push(linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/files/yorumlar/', item.image_url) ?? '');
  });

  const [ isLoaded, setIsLoaded ] = useState(false);
  const [ testimonialList, setTestimonialList ] = useState(list);
  const [ opacities, setOpacities ] = useState({ list: [''], fallback: '' });

  const swiperMoved = (swp?: any) => {
    setTestimonialList((current: Record<string,any>) => {
      return current.map((item: Record<string,any>, index: number) => {
        index += index < (swp?.realIndex ?? 0) ? current.length : 0;
        
        item.opacity = opacities.list[index - (swp?.realIndex ?? 0)] ?? opacities.fallback;
        
        return item;
      });
    });
  };

  useEffect(() => {
    handleResize();
    
    setIsLoaded(true);

    window?.addEventListener('resize', handleResize);
    return () => { window?.removeEventListener('resize', handleResize); };
  }, []);

  if(!testimonialList?.length) return (
    <div className="h-10"></div>
  );

  return (
    <>
      <div className={`${isLoaded ? 'opacity-100 transition-all duration-300' : 'opacity-0'} container relative mt-10 lg:mt-16 mb-5 lg:mb-8`}>
        <div className="title flex justify-between items-center">
          <DPIcon icon="d-quote" className="absolute -left-[20px] sm:-left-[60px] -top-[20px] -rotate-[20deg] opacity-5 text-[12rem] z-0" />
          <h2 className="font-bold">
            <div>{ heading?.caption ?? '' }</div>
            <div className="text-lava text-2xl sm:text-[2.5rem]">{ heading.subcap }</div>
          </h2>
          <LoadingLink href={`/${rootSlug}`} title={`Doping Hafıza ${ heading.subcap }`} className="flex items-center text-honolulu-blue text-sm font-semibold">
            { heading?.linkCap ?? _e('Tümünü Gör') }
            <DPIcon icon="arrow-right" />
          </LoadingLink>
        </div>
      </div>

      <div className={`${isLoaded ? 'opacity-1 transition-all duration-300' : 'opacity-0'} __sample-videos-swiper overflow-hidden relative`}>
        <div className="swiper-content relative block mx-0 lg:-mx-[230px]">
          <Swiper
            loop={isLoaded}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              640: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
              1280: {
                slidesPerView: 5,
              },
            }}
            navigation={{
              nextEl: '.swiper-button-next-testimonial',
              prevEl: '.swiper-button-prev-testimonial',
            }}
            modules={[Navigation]}
            className="flex items-stretch"
            onActiveIndexChange={swiperMoved}
          >
            {
              isLoaded && testimonialList?.map((item: any, index: number) => {
                return (
                  <SwiperSlide
                    key={item.id}
                    className={`flex justify-center items-stretch py-12 px-5 select-none flex-grow ${item?.opacity ?? ''} !transition-all duration-300`}
                  >
                    <div
                      className="flex flex-col justify-between items-stretch flex-grow bg-white shadow-md hover:shadow-xl transition-all duration-300 rounded-lg px-6 pt-6 pb-5 text-sm cursor-pointer"
                      onClick={ () => openLightbox(index + 1) }
                    >
                      <div className="relative flex justify-streatch items-stretch flex-grow">
                        {/* <div className="flex justify-stretch items-center flex-grow min-h-[200px] text-start">
                          { item?.caption ?? item?.name }
                        </div>
                        <div className="relative flex justify-center items-center flex-grow-0 flex-shrink-0 w-1/3">
                          <Image
                            src={ linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/files/yorumlar/thumbnail/', item.thumbnail_url ?? item.image_url) ?? '' }
                            alt={item.name}
                            fill={true}
                            sizes="100%"
                            style={{ objectFit: 'contain', objectPosition: 'center' }}
                          />
                        </div> */}
                        <div className="relative flex justify-center items-center flex-grow-0 flex-shrink-0 w-full aspect-video">
                          <Image
                            src={ linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/files/yorumlar/thumbnail/', item.thumbnail_url ?? item.image_url) ?? '' }
                            alt={item.name}
                            fill={true}
                            sizes="100%"
                            style={{ objectFit: 'cover', objectPosition: 'center' }}
                          />
                        </div>
                      </div>

                      <div className="font-semibold border-t-[1px] mt-3 pt-3">
                        { item?.name }, { item?.commentator_type }
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })
            }
          </Swiper>
          
          <div className="custom-buttons hidden md:flex justify-center gap-4">
            <div className="swiper-button-prev-testimonial">
              <DPIcon className="text-4xl text-slate-400 transition-all hover:text-honolulu-blue cursor-pointer" icon="arrow-circle-left" />
            </div>
            <div className="swiper-button-next-testimonial">
              <DPIcon className="text-4xl text-slate-400 transition-all hover:text-honolulu-blue cursor-pointer" icon="arrow-circle-right" />
            </div>
          </div>
        </div>
      </div>

      <FsLightbox
        toggler={lightboxController.toggler}
        slide={lightboxController.slide}
        sources={lightBoxSource}
        types={lightBoxSource.map(() => 'image')}
      />
    </>
  );
}
